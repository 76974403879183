<template>
	<v-select
		v-bind="$attrs"
		v-on="$listeners"
		:value="value"
		@input="$emit('input', $event)"
		:items="items"
	>

	</v-select>
</template>

<script>
import DeviceModel from '../../devices/Model';

const deviceModel = new DeviceModel();

export default {
	props: {
		value: {},
	},
	data(){
		return {
			items: [],
		}
	},
	created() {
		deviceModel.getDeviceTypes().then(list=>{
			this.items = [];
			for (const k in list) {
				this.items.push({
					value: k,
					text: list[k],
				});
			}
		});
	},
}
</script>