<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col cols="12" class="d-flex">
					<session-select
						:utility="utility"
						v-model="selectedSession"
						:session.sync="sessionRow"
						:readonly="loading"
						dense outlined hide-details single-line label="Сесия"
						style="max-width: 400px"
						class="mr-2"
					/>
					<station-select-all
						:utility="utility"
						:session="session"
						v-model="selectedStation"
						:readonly="loading"
						clearable
						dense outlined hide-details single-line label="Станция"
						style="max-width: 600px"
						class="mr-2"
					/>
					<dev-type-list multiple
						v-model="deviceTypes"
						dense outlined hide-details single-line label="Тип уред"
						class="mr-2"
					/>
					<v-select clearable dense outlined hide-details single-line
						class="mr-2"
						label="Отчетен период"
						v-model="mesechen"
						:items="[
							{value:0,text:'Годишен'},
							{value:1,text:'Месечен'},
						]"
					/>
					<v-select multiple dense outlined hide-details single-line
						class="mr-2"
						label="Тип отчет"
						v-model="otchetTypes"
						:items="[
							{value:1,text:'Редовен'},
							{value:2,text:'Самоотчет'},
							{value:3,text:'Служебен'},
							{value:4,text:'Нередовен'},
						]"
					/>
					<v-spacer/>
				</v-col>
			</v-row>
			<v-row><v-col><v-divider/></v-col></v-row>
			<v-row v-for="(rule, key) in filterData" :key="key" dense>
				<v-col cols="3"><filter-field :imodel="imodel" v-model="rule.field" readonly /></v-col>
				<v-col cols="3">
					<div class="d-flex align-center">
						<v-switch v-model="rule.not" label="NOT" dense hide-details class="mt-0 mr-2" />
						<filter-op :imodel="imodel" v-model="rule.op" :rule="rule" clearable />
					</div>
				</v-col>
				<v-col cols="5"><filter-value :imodel="imodel" v-model="rule.value" :rule="rule" class="mt-0" /></v-col>
			</v-row>
			<v-row>
				<v-col>
					<search @filter="customRules = $event.rules" :imodel="imodel" />
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<list
						ref="dataTable"
						hide-edit hide-select hide-delete
						v-if="session"
						:imodel="imodel"
						:params="listParams"
						:filter="filter"
						:headers="headers.join(',')"
						item-key="n_device_data.id"
						hidden-cols="n_device_data.id,_id.imot_id,_sd.station_id"
					>
						<template #right-actions="{item}">
							<imot-details-link
								target-blank
								:utility="utility"
								:session="session"
								:station="item['_sd.station_id']"
								:imot="item['_id.imot_id']"
							/>
						</template>
					</list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import DevTypeList from "./devTypeList";
import List from "@/ittijs/List";
import DeviceDataModel from './Model';
import StationSelectAll from "@/views/stations/StationSelectAll";
import FilterField from "@/ittijs/Filter/Field";
import FilterValue from "@/ittijs/Filter/Value";
import FilterOp from "@/ittijs/Filter/Op";
import Search from "@/ittijs/Search";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

const model = new DeviceDataModel();

export default {
	components: {
		ImotDetailsLink,
		Search,
		FilterOp,
		FilterValue,
		FilterField,
		StationSelectAll,
		List,
		DevTypeList,
		PleaseSelectUtility,
		SessionSelect,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility:'selectedUtility',
			session:'selectedSession',
			station:'selectedStation',
		}),
	],
	props: [
		'utility',
		'session',
		'station',
	],
	computed: {
		listParams(){
			return {
				utility    : this.utility,
				session    : this.session,
				station    : this.station,
				deviceTypes: this.deviceTypes,
				mesechen   : this.mesechen,
				otchetType : this.otchetTypes,
			}
		},
		filter(){
			return {
				glue: "AND",
				rules: [
					...Object.values(this.filterData),
					...(this.customRules && Object.values(this.customRules) || []),
				],
			}
		},
		isIzravnitelna(){
			return this.sessionRow && this.sessionRow.izr_sess != '0';
		},
		headers(){
			return [
				'_sd.station_N',
				'_id.imot_N',
				'_id.address',
				'n_device_data.device_N',
				'_m.is_radio',
				'_d_created_time',
				'n_device_data.device_type_code',
				'_d.room',
				'n_device_data.value_old',
				'n_device_data.value_new',
				'value_diff',
				...(this.isIzravnitelna ? [
					'n_device_data.value_old2',
					'n_device_data.value_new2',
					'value_diff2',
					'value_diff_combined',
				] : []),
				'n_device_data.otchet_type',
				'consumption',
				'n_device_data.radiator_factor',
				'n_device_data.radiator_power',
				'n_device_data.radiator_description',
				'power_div_factor',
				'q_max',
				'q_max_diff',
			];
		},
	},
	watch: {
		selectedUtility() {
			this.selectedSession = null;
			this.selectedStation = null;
		},
	},
	data(){
		return {
			selectedSession: null,
			selectedStation: null,
			loading: false,
			sessionRow: {},
			deviceTypes: [],
			mesechen: null,
			otchetTypes: [],
			imodel: model,
			filterData: {
				deviceN     : {field:'n_device_data.device_N',        op:null, value:null},
				isRadio     : {field:'_m.is_radio',                   op:null, value:null},
				value       : {field:'n_device_data.value_new',       op:null, value:null},
				diff1       : {field:'value_diff',                    op:null, value:null},
				diff2       : {field:'value_diff2',                   op:null, value:null},
				diff        : {field:'value_diff_combined',           op:null, value:null},
				consumption : {field:'consumption',                   op:null, value:null},
				factor      : {field:'n_device_data.radiator_factor', op:null, value:null},
				power       : {field:'n_device_data.radiator_power',  op:null, value:null},
				powerFactor : {field:'power_div_factor',              op:null, value:null},
				created     : {field:'_d_created_time',               op:null, value:null},
				qMaxDiff    : {field:'q_max_diff',                    op:null, value:null},
			},
			customRules: {},
		}
	},
}
</script>